const black = {
  // lightest
  // lighter
  // light
  main: "#313449" as const,
  // dark
  // darker
  // darkest
};

const gray = {
  lightest: "#F9F7F3" as const,
  lighter: "#D1D1D1" as const,
  light: "#999EA1" as const,
  main: "#6B6E81" as const,
};

const blue = {
  lightest: "#FBFDFF" as const,
  light: "#E4EBFF" as const,
  main: "#6B8EF0" as const,
};

const orange = {
  // lightest
  lighter: "#FFF4DD" as const,
  light: "#FFDA9D" as const,
  main: "#FF8811" as const,
  dark: "#E98C00" as const,
  darker: "#DE6F01" as const,
  // darkest
};

const tan = {
  // lightest: "#FFFDF9" as const,
  lightest: "#FFFDF9" as const, // TO DO: determine if we should use this or the one above
  lighter: "#FFF9EE" as const,
  light: "#F0ECE2" as const,
  main: "#9C8E6E" as const,
  dark: "#65532D" as const,
};

const yellow = {
  lightest: "#FFF8E8",
  main: "#FFD801",
  dark: "#FFE266",
};

const gold = {
  lightest: "#fefcf5" as const,
  lighter: "#FFFCF4" as const,
  light: "#FCF0DA" as const,
  main: "#FFBB37" as const,
  // dark
  // darker
  // darkest
};

const green = {
  lightest: "#C8F8E4" as const,
  main: "#0DBE75" as const,
};

const red = {
  light: "#FFEDE9" as const,
  main: "#EC8276" as const,
};

const purple = {
  faded: "#FBF8FF" as const,
};

export const colors = {
  black,
  blue,
  purple,
  gray,
  gold,
  green,
  orange,
  red,
  tan,
  text: {
    black: black.main,
    gray: gray.main,
    tan: tan.main,
    tanDark: tan.dark,
    blue: blue.main,
  },
  border: {
    grayLight: gray.lightest,
    gray: gray.light,
    tan: tan.light,
  },
  white: "#FFFFFF",
  yellow,
  level: {
    at: "#E0FFBC",
    above: "#FFFDD3",
    wayAbove: "#FFF2F1",
  },
};
